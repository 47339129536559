const text = {
    svg: draw.circle(0).fill('#fff'),
    x: 0,
    y: 60,

    draw() {
        $('button').prop('disabled', false);
        logo.draw();
        let w = 100;
        let h = 0;
        let left = 0;

        text.svg.remove();
        text.svg = draw.group();

        let color = $('input[name=textcolor]:checked').val();
        let pos = $('input[name=pos]:checked').val();
        let mode = $('input[name=mode]:checked').val();
        let chapter = $('input[name=chapter]:checked').val();

        $('.no-women').attr('disabled', false);
        $('.no-youth').attr('disabled', false);

        switch(chapter) {
            case 'frauen':
                $('.no-women').attr('disabled', true);
                $('.no-women').parent().attr('title', 'Diese Möglichkeit steht nur für die Standardversion zur Verfügung');
                break;
            case 'youth': case 'youth':
                $('.no-youth').attr('disabled', true);
                $('.no-youth').parent().attr('title', 'Diese Möglichkeit steht nur für die Standardversion zur Verfügung');
                break;
        }

        switch(mode) {
            case 'blackwhite':
                color = '#000000';
                break;
            case 'blackwhiteinverse':
                color = '#ffffff';
                break;
            case 'colorinverse':
                color = '#ffffff';
                break;
            case 'transparent':
                color = '#ffffff';
                $('button[data-format="jpg"]').prop('disabled',true);
                break;
        }

        $('#canvas').toggleClass('bg-chess', (color == '#ffffff'));

        let anchor = (pos == 'left') ? 'end' : 'start';
  
        let text1 = draw.text($('#text1').val().replace(/ /,"\n")).fill(color).font(
            {
                family: 'Poppins',
                size: 15,
                anchor: anchor,
                weight: 700
            }
        );

        let y = text1.bbox().h + 6;
        let text2 = draw.text($('#text2').val()).fill(color).y(y).font(
            {
                family: 'Poppins',
                size: 15,
                anchor: anchor,
                weight: 400,
                leading: '1.0em'
            }
        );

        text.svg.add(text1);
        if ($('#text2').val()) {
            text.svg.add(text2);
        }

        switch (pos) {
            case 'left':
                text.svg.move(0, logo.svg.height() - text.svg.height() - 4);
                logo.svg.x(text.svg.width());
                draw.height(logo.svg.height());
                draw.width(logo.svg.x() + logo.svg.width() + 4);
                break;
            case 'right':
                left = 120 + (text.svg.height() * Math.tan(26.5 * Math.PI / 180));
                if( chapter == 'frauen' ) {
                    logo.svg.x(15);
                    left += 15
                } else {
                    logo.svg.x(0);
                }
                text.svg.move(left, logo.svg.height() - text.svg.height() - 4);
                draw.height(logo.svg.height());
                draw.width(text.svg.x() + text.svg.width() + 4);
                break;
            case 'under':
                draw.height(200);
                text.svg.move(1, 107);
                logo.svg.x(0);
                draw.height(text.svg.y() + text.svg.height() + 4);
                draw.width(Math.max(text.svg.width(), logo.svg.width()));
                break;
        }

        try{
            chapterSVG.remove();
        } catch(e) { }

        let chapterColor = 'blue';
        switch( chapter ) {
            case 'frauen':
                switch(mode) {
                    case 'blackwhiteinverse':  case 'colorinverse':  case 'transparent':
                        chapterColor = '#ffffff'
                        break;
                    case 'blackwhite':
                        chapterColor = '#000000'
                        break;
                    default:   
                        chapterColor = $('input[name=womenColor]:checked').val();
                }
                chapterSVG = draw.path(pattern.frauen).move(logo.svg.x() - 15, logo.svg.y() + logo.svg.height() - 3 ).size(128).fill(chapterColor)
                draw.height(draw.height() + chapterSVG.bbox().h + 4);
                break;
            case 'youth':
                switch(mode) {
                    case 'blackwhiteinverse':  case 'colorinverse':  case 'transparent':
                        chapterColor = '#ffffff'
                        break;
                    case 'blackwhite':
                        chapterColor = '#000000'
                        break;
                    default:   
                        chapterColor = '#000000';
                }
                if(color == '#ffffff') {
                    chapterColor = '#ffffff';
                }
                chapterSVG = draw.path(pattern.jugend).move(logo.svg.x() + 3, logo.svg.y() + logo.svg.height() + 2 ).size(105).fill(chapterColor)
                draw.height(draw.height() + chapterSVG.bbox().h + 4);
                break;

        }

        try{
            bg.remove();
        } catch(e) {
        }
        switch(mode) {
            case 'blackwhiteinverse':
                bg = draw.rect(draw.width(), draw.height()).fill('#000000').back();
                break;
            case 'colorinverse':
                bg = draw.rect(draw.width(), draw.height()).fill('#ed1c24').back();
                break;
            default:   
        }
       
    },
};

$('#text1, #text2').bind('input propertychange', text.draw);


$('input[name="chapter"][value="standard"]').bind('input propertychange', function() {
    $('input[name="youthColor"][value="#000000"]').prop('checked', true);
    logo.draw();
    text.draw();
});

$('input[name="chapter"][value="frauen"]').bind('input propertychange', function() {
    $('input[name="pos"][value="right"]').prop('checked', true);
    $('input[name="mode"][value="standard"]').prop('checked', true);
    $('input[name="youthColor"][value="#000000"]').prop('checked', true);
    logo.draw();
    text.draw();
});

$('input[name="chapter"][value="youth"]').bind('input propertychange', function() {
    $('input[name="pos"][value="right"]').prop('checked', true);
    $('input[name="mode"][value="standard"]').prop('checked', true);
    logo.draw();
    text.draw();
});

$( 'input[name="youthColor"]').on('click', function() {
    $('input[name="chapter"][value="youth"]').click();
    logo.draw();
    text.draw();
});

$( 'input[name="womenColor"]').on('click', function() {
    $('input[name="chapter"][value="frauen"]').click();
    logo.draw();
    text.draw();
});

$('input[name="textcolor"]').on('click', function() {
    $('input[name="mode"][value="standard"]').prop('checked', true);
    text.draw();
});

$('input[name="pos"], input[name="mode"]').on('click', text.draw);

