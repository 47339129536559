$('.download').click(function () {
    $('.downlaod').prop("disabled", true);
    let description = $(this).html();
    $(this).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Augenblick bitte');
   // $('.canvas').addClass('opacity');

    let format = $(this).data('format');
    // if( format == 'png' || format == 'svg') {
    //     console.log("hide background")
    //     background.hide();
    // }
    // if( format == 'jpg') {
    //     background.show();
    //     console.log("show background")
    // }

    let data = draw.svg();
    let thisButton = this;

    $.ajax({
        type: "POST",
        url: 'createpic.php',
        data: {svg: data, format: format, text: $('#text2').val() },
        success: function (data, textStatus, jqXHR) {
            let obj = JSON.parse(data);
            $('.download').prop("disabled", false);
            $('.canvas').removeClass('opacity');
            $(thisButton).html(description);
            let downloadname = 'dgb-logo';

            window.location.href = 'download.php?file=' + obj.basename + '&format=' + format + '&downloadname=' + downloadname;
        }
    });
});
