var draw;
draw = SVG().addTo('#canvas');
draw.size( 400, 100 );


$(document).ready(function () {
    logo.draw();
    text.draw();

    window.setTimeout(function () {
        text.draw();
    }, 1000);
});


